<template>
  <div>
    <div class="card-carousel-wrapper">
      <!-- Nav Left -->
      <div class="card-carousel-nav-left"
           @click="moveCarousel(-1)"
           :disabled="atHeadOfList"
           :class="{
             'hidden' : albums && albums.length <= musicColumnItems
           }"
      ></div>

      <!-- Card -->
      <div class="card-carousel">
        <div class="card-carousel-cards"
             :style="{transform : 'translateX(' + currentOffset + 'px)'}">
          <card class="card-carousel-card"
                v-for="album in albums"
                :key="album.mobile_album_id"
                :album="album"
                :columnItems="musicColumnItems"
                :cardCarouselWidth="cardCarouselWidth"
          ></card>
        </div>
      </div>

      <!-- Nav Right -->
      <div class="card-carousel-nav-right"
           @click="moveCarousel(1)"
           :disabled="atEndOfList"
           :class="{
             'hidden' : albums && albums.length <= musicColumnItems
           }"
      ></div>
    </div>
    
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  const Card = () => import('@/components/user/category/AlbumCarouselCard.vue');

  export default {
    name   : 'AlbumCarousel',
    mixins : [
      BreakpointMixin,
    ],
    props : {
      categoryId : Number,
    },
    components : {
      Card,
    },
    data() {
      return {
        currentOffset     : 0,
        cardCarouselWidth : 0,
        albums            : [],
      }
    },
    computed : {
      atEndOfList() {
        return this.currentOffset <= ((this.paginationFactor * -1) *
          (this.albums.length - this.musicColumnItems));
      },
      atHeadOfList() {
        return this.currentOffset >= 0;
      },
      paginationFactor() {
        return Math.round(this.cardCarouselWidth / this.musicColumnItems);
      },
    },
    methods : {

      /**
       * Move Carousel either left or right
       *
       * @param {integer} direction either -1 (left) or 1 (right)
       */
      moveCarousel(direction) {
        // Find a more elegant way to express the :style.
        //  consider using props to make it truly generic
        if (direction === 1 && !this.atEndOfList)
          this.currentOffset -= this.paginationFactor;

        else if (direction === -1 && !this.atHeadOfList)
          this.currentOffset += this.paginationFactor;
      },

      /**
       * Get All Category Albums
       * @param categoryId
       */
      getCategoryAlbums(categoryId) {
        this.$http.get('api/music/categoryalbum', {
          params : {
            mCategoryId : categoryId,
            page        : 1,
            perPage     : 100,
          },
        }).then(response => {
          this.albums = response.data.result.data;
        });
      },
    },
    mounted() {
      this.getCategoryAlbums(this.categoryId);

      this.$nextTick(function() {
        this.cardCarouselWidth =
          this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      });
    },
    updated() {
      this.cardCarouselWidth =
        this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      this.getWindowWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/category/album-carousel";
</style>